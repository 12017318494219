<template>
  <div class="notice-board-page">
    <v-row no-gutters class="mx-2 my-2" justify="center">
      <v-col md="5">
        <div v-if="isLoading">
          <v-skeleton-loader
            v-for="x in 3"
            :key="x"
            elevation="1"
            class="my-2 mx-2"
            type="list-item-avatar, list-item-three-line"
          ></v-skeleton-loader>
        </div>
        <div
          v-if="!posts.length && !isLoading && this.room"
          class="text-center mt-2"
        >
          <h3>No posts found.</h3>
        </div>

        <div class="posts-container" v-else>
          <v-card
            v-for="post in posts"
            :key="post.id"
            :to="{ name: 'ViewBoardPost', params: { postId: post.id, room } }"
            class="my-2 post-card"
          >
            <v-list-item>
              <v-list-item-avatar>
                <v-img :src="post.user.image"></v-img>
              </v-list-item-avatar>
              <v-list-item-content class="subtitle-2">
                {{ post.user.full_name }}
              </v-list-item-content>
              <p class="my-0 caption grey--text">
                {{ moment(post.created).format("hh:mmA, Do MMM") }}
              </p>
            </v-list-item>
            <v-card-text class="py-1 pb-2">
              <div class="black--text subtitle-1 content-description">{{ post.description | truncate(150, '...') }}</div>
              <v-img
                v-if="post.board_images.length"
                :src="post.board_images[0].image"
              ></v-img>
            </v-card-text>

            <v-card-actions
              class="pt-0 ml-1"
              v-if="post.board_images.length || post.board_documents.length || post.is_draft"
            >

              <v-chip small
                color="orange lighten-2 mr-1"
                v-if="post.is_draft && post.scheduled"
              >Scheduled</v-chip>

              <v-chip
                small
                color="light-blue lighten-4 mr-1"
                v-if="post.board_images.length && post.board_images.length > 1"
                >+{{ post.board_images.length - 1 }} Images</v-chip
              >
              <v-chip
                small
                color="teal lighten-4"
                v-if="post.board_documents.length"
                >{{ post.board_documents.length }} Document(s)</v-chip
              >
            </v-card-actions>

              <div v-if="post.rooms.length > 1" class="mx-3 pb-2">
                <v-divider class="my-2"></v-divider>
                <v-chip 
                  small
                  color="primary"
                  class="my-1 mr-1"
                  v-for="room in post.rooms"
                  :key="room.id"
                  outlined
              
                >
                  {{ allRooms.find(r => r.id == room) ? allRooms.find(r => r.id == room).sections : "" }}  
                </v-chip>
              </div>
          </v-card>
        </div>
      </v-col>
    </v-row>
    <!-- <mugen-scroll :handler="() => getClassroomMessages(true)" :should-handle="!moreLoading">
      <div style="height: 20px"></div>
    </mugen-scroll> -->
    <infinite-loading
      v-if="room"
      ref="classroom-messages-infinite-loader"
      spinner="waveDots"
      :identifier="infiniteIdentifier"
      @infinite="getClassroomMessages"
    >
      <div slot="no-results" class="mb-16">
        No more messages!
      </div></infinite-loading
    >
    <v-fab-transition v-if="currentUser.is_faculty && !isLoading">
      <v-btn
        style="z-index: 100; bottom: 72px"
        color="primary"
        class="non-focused"
        @click="messageCreateSheet.visible = true"
        dark
        bottom
        right
        fixed
        fab
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>
    <intract-create-edit-sheet
    persistent
      v-if="!currentUser.is_student"
      title="Send Message"
      description="This will notify all the students of this particular classroom"
      :visible="messageCreateSheet.visible"
      @close="messageCreateSheet.visible = false"
      :fields="formFields"
      enable-media
      :endpoint="endpoints.boardPosts"
      :edit-id="messageCreateSheet.editId"
      :data-object="messageCreateSheet.obj"
      :create-success-message="messageCreateSheet.createSuccessMessage"
      @objectCreated="messageSent"
      @updateObject="(obj) => (messageCreateSheet.obj = obj)"
    >
      <!-- schedule for later button slot -->
      <template v-slot:after-submit-button v-if="!messageCreateSheet.obj.scheduled || !(messageCreateSheet.obj.rooms && messageCreateSheet.obj.rooms.length >= 1)">
        <v-dialog
            v-if="!messageCreateSheet.obj.scheduled"
            v-model="scheduleDialog.visible"
            width="500"
            persistent
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="blue"
                outlined
                block
                dark
                v-bind="attrs"
                v-on="on"
              >
                Schedule for Later
              </v-btn>
            </template>

            <v-card>
              <v-card-title class="text-h5 grey lighten-2">
                Schedule for later
               <v-btn icon absolute top right @click="scheduleDialog.visible=false; scheduleDialog.datetime=null">
                 <v-icon>mdi-close</v-icon>
               </v-btn>
              </v-card-title>

              <v-card-text>
                <v-text-field class="mt-4 mb-0" hide-details="auto" outlined type="datetime-local" label="Schedule Date & Time" v-model="scheduleDialog.datetime">
                </v-text-field>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  block
                  :disabled="!scheduleDialog.datetime"
                  @click="messageCreateSheet.obj.scheduled = scheduleDialog.datetime; scheduleDialog.visible = false; scheduleDialog.datetime = null"
                >
                  Schedule 
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog> 

      <!-- broadcast to other classrooms -->
        <v-dialog
            v-if="!(messageCreateSheet.obj.rooms && messageCreateSheet.obj.rooms.length >= 1)"
            v-model="broadcastDialog.visible"
            width="500"
            persistent
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mt-2"
                color="blue"
                outlined
                block
                dark
                v-bind="attrs"
                v-on="on"
              >
                Broadcast to other Classrooms
              </v-btn>
            </template>

            <v-card>
              <v-card-title class="text-h5 grey lighten-2">
                 <v-row>
                  <v-col cols="10">
                    <p class="mb-0" style="word-break: break-word">Broadcast to other classrooms</p>
                    <v-card-subtitle class="mb-1 py-0 px-1" style="word-break: break-word">with {{ room.sections }}</v-card-subtitle>
                  </v-col>
                </v-row>
                <v-btn icon absolute top right @click="broadcastDialog.visible=false; broadcastDialog.classrooms=null">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-card-title>

              <v-card-text>
                <v-select
                  v-model="broadcastDialog.classrooms"
                  :items="allRooms.filter(r => r.id != room.id)"
                  color="primary"
                  outlined
                  multiple
                  small-chips
                  deletable-chips
                  class="mt-3"
                  label="Select Classrooms"
                  hide-details
                  item-value="id"
                  item-text="sections"
                  clearable
                  :rules="[rules.requiredArray]"
                ></v-select>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  block
                  :disabled="!broadcastDialog.classrooms"
                  @click="messageCreateSheet.obj.rooms = broadcastDialog.classrooms; broadcastDialog.visible = false; broadcastDialog.classrooms = null"
                >
                  Broadcast 
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog> 
      </template>

      <!-- scheduled for information -->
      <template v-slot:custom-field--scheduled="{ obj }">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="text-wrap">
              Scheduled for <strong class="orange--text">{{ moment(obj.scheduled).format('LLL') }}</strong>
            </v-list-item-title>
            <v-list-item-subtitle>
              Will be published at the specified time.
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-icon @click="obj.scheduled = null">mdi-close</v-icon>
          </v-list-item-action>
        </v-list-item>
      </template>
  
    <!-- broadcast information -->
       <template v-slot:custom-field--rooms="{ obj }">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="text-wrap">
              Broadcast to
            </v-list-item-title> 
            <v-list-item-subtitle>
                <v-chip v-for="r in obj.rooms" :key="r" color="primary" outlined small class="mr-1 mt-1">
                    {{ allRooms.find(room => room.id == r) ? allRooms.find(room => room.id == r).sections : "" }}  
                </v-chip>
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-icon @click="obj.rooms = []">mdi-close</v-icon>
          </v-list-item-action>
        </v-list-item>
      </template>  

    </intract-create-edit-sheet>

  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import api from "@api/index";
import endpoints from "@api/repository";
import Helper from "@utils/misc";
import moment from "moment";
import InfiniteLoading from "vue-infinite-loading";
import IntractCreateEditSheet, {
  CreateEditFieldTypes,
} from "@components/generics/IntractCreateEditSheet.vue";
// import MugenScroll from "vue-mugen-scroll";
// import CreateBoardPostSheet from "@components/board/CreateBoardPostSheet";
import EventBus from "@utils/event_bus";

export default {
  name: "ClassroomMessages",
  props: {
    room: {
      type: Object,
      default: () => null,
    },
  },

  data() {
    return {
      endpoints: endpoints,
      moment: moment,
      moreLoading: false,
      pagination: {
        nextUrl: null,
        count: null,
      },
      scheduleDialog: {
        visible: false,
        datetime: null,
      },
      broadcastDialog: {
        visible: false,
        classrooms: null,
      },
      rules: {
        // required: (v) => !!v || "Required",
        requiredArray: (v) => !!(v && v.length) || "Required",
      },
      firstLoad: true,
      posts: [],
      messageCreateSheet: {
        visible: false,
        createSuccessMessage: "Message sent successfully!",
        editId: null,
        obj: {
          is_alert: true,
          description: "",
          institution: null,
          first_section: null,
          second_section: null,
          third_section: null,
          recipients: [],
          scheduled: null,
          rooms: []
        },
      },
      // showCreateBoardPostSheet: false,
      infiniteIdentifier: new Date().toString(),
    };
  },
  components: {
    // MugenScroll,
    IntractCreateEditSheet,

    InfiniteLoading,
    // CreateBoardPostSheet,
  },
  computed: {
    ...mapGetters([
      "isLoading",
      "currentUser",
      "accessToken",
      "currentInstitution",
      "allRooms",
    ]),
    essentials() {
      return {
        accessToken: this.accessToken,
        handleErrorsFunction: this.handleApiError,
        setLoading: this.setLoading,
      };
    },
    
    formFields() {
      return {
        description: {
          fieldType: CreateEditFieldTypes.TEXTAREA,
          label: "Description",
          required: true,
          md: 12,
          max: 3000,
        },
        allow_replies: {
          fieldType: CreateEditFieldTypes.CHECKBOX,
          label: "Allow Replies",
          helper: "Will allow students to add replies to this notice. Replies will also be visible to other students.",
          required: false,
          md: 12,
        },
        scheduled: {
          fieldType: CreateEditFieldTypes.CUSTOMFIELD,
          hide: this.messageCreateSheet.obj.scheduled ? false: true,
        },
        rooms: {
          fieldType: CreateEditFieldTypes.CUSTOMFIELD,
          hide: (this.messageCreateSheet.obj.rooms && this.messageCreateSheet.obj.rooms.length >= 1) ? false: true,          
        },
        board_images: {
          fieldType: CreateEditFieldTypes.IMAGEUPLOAD,
          multiple: true,
          label: "Images",
          helper: "JPG, PNG, GIF, etc",
        },
        board_documents: {
          fieldType: CreateEditFieldTypes.FILEUPLOAD,
          multiple: true,
          label: "Documents",
          helper: "PDF, Video, Audio, Docs (Upto 30MB)",
        },
      };
    },
  },
  methods: {
    ...mapActions(["showSnackbar", "setLoading"]),

    async messageSent(data) {
      this.getClassroomMessages();
      this.$router.push({ name: "ViewBoardPost", params: { postId: data.id, room: this.room } });
    },

    async getClassroomMessages(state) {
      if (!this.room) return;
      if (!state) {
        //refresh
        this.pagination.nextUrl = this.pagination.count = null;
        this.posts = [];
        this.firstLoad = true;

        // this.infiniteIdentifier = new Date().toString();
        this.infiniteIdentifier = new Date().toString();
        return;
        // return;
      }
      // if (this.isMoreLoading) return;
      // if (loadMore && !this.pagination.nextUrl) return;
      console.log("works fine");

      var endpoint_url = Helper.addUrlParams(endpoints.boardPosts, [
            "is_alert=true",
            "rooms=" + this.room.id,
          ])
        
      if(this.currentUser.is_student) endpoint_url = Helper.addUrlParams(endpoint_url, 'is_draft=false'); //hide drafts from students

      var url = this.firstLoad
        ? endpoint_url
        : this.pagination.nextUrl;
      var response = await api.call(this.essentials, url);
      if (response) {
        this.firstLoad = false;
        this.posts.push(...response.results);
        this.pagination.nextUrl = response.next;
        this.pagination.count = response.count;
        if (state) {
          if (this.posts.length == response.count) state.complete();
          else state.loaded();
        }
      }
    },

    async handleApiError(err) {
      this.moreLoading = false;
      console.log(err);
      this.setLoading(false);
      if (err.response) {
        this.showSnackbar({
          title: "Something went wrong",
          text: err.response.data.message,
          type: "error",
        });
      } else {
        this.showSnackbar({
          title: "Something went wrong",
          type: "error",
        });
      }
    },

    async initListeners() {
      EventBus.$on(
        "view_board_post__post_deleted",
        (id) => (this.posts = this.posts.filter((p) => p.id != id))
      );

      EventBus.$on("view_board_post__post_updated", (post) => {
        var listPost = this.posts.find((p) => p.id == post.id);
        if (listPost) {
          listPost.description = post.description;
          listPost.board_images = post.board_images;
          listPost.board_documents = post.board_documents;
          listPost.rooms = post.rooms;
        }
      });
    },
  },

  created() {
    this.initListeners();
    this.messageCreateSheet.obj.institution = this.currentInstitution.id;
    this.messageCreateSheet.obj.user = this.currentUser.id;
    this.messageCreateSheet.obj.recipients.push(this.currentUser.id);
  },
  watch: {
    room() {
      if (this.room) {
        this.getClassroomMessages();
        this.messageCreateSheet.obj.first_section = this.room.first_section?.id;
        this.messageCreateSheet.obj.second_section =
          this.room.second_section?.id;
        this.messageCreateSheet.obj.third_section = this.room.third_section?.id;
      }
    },
  },
};
</script>
<style scoped>
.fab-transition-enter {
  transition-delay: 2s;
}
</style>