var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"board-page"},[_c('v-tabs',{attrs:{"fixed-tabs":""}},[_c('v-tab',{staticClass:"non-focused"},[_c('strong',[_vm._v("For Class")]),(_vm.selectedRoom && !_vm.currentUser.is_student)?_c('v-icon',{staticClass:"ml-2",attrs:{"small":""},on:{"click":function($event){_vm.selectedRoom = null}}},[_vm._v("mdi-pencil")]):_vm._e()],1),_c('v-tab',{staticClass:"non-focused"},[(_vm.currentUser.is_student)?_c('strong',[_vm._v("For You")]):_c('strong',[_vm._v("Students")])]),_c('v-tab-item',[_c('classroom-messages',{attrs:{"room":_vm.selectedRoom}})],1),_c('v-tab-item',{key:_vm.studentMessagesKey},[(_vm.currentUser.is_student)?_c('div',[(_vm.currentUser.is_student)?_c('individual-student-messages'):_vm._e()],1):_c('div',[_c('student-messages-list',{attrs:{"room":_vm.selectedRoom}})],1)])],1),(!_vm.currentUser.is_student)?_c('choose-room-dialog',{attrs:{"visible":!_vm.selectedRoom},on:{"roomSelected":(r) => {
      if(_vm.currentUser.is_admin || (_vm.currentUser.is_faculty && r.class_teachers && r.class_teachers.map(ct => ct.id).includes(_vm.currentUser.id))){
        _vm.selectedRoom = r;
      } else {
          this.showSnackbar({ title: 'Not authorized to view/create messages for this classroom!', type: 'error' });
          return 
      }
    }}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }